import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useCentralizedSnackbar from '../../shared/hooks/redux-use-centralized-snackbar';
import { useEnhedId } from '../../shared/hooks/use-enhed-id';
import DataGrid, { Column, ColumnChooser, GroupPanel, Item, SearchPanel, StateStoring, Toolbar } from 'devextreme-react/data-grid';
import Step from '@mui/material/Step';
import Stepper from '@mui/material/Stepper';
import StepLabel from '@mui/material/StepLabel';
import { NavLink } from 'react-router-dom';
import DxButton from 'devextreme-react/button';
import useQueryParam from '../../shared/hooks/use-query-param';
import { useEffect } from 'react';
import useFetch from '../../shared/hooks/fetch-hooks/use-fetch';
import LoadingScreen from '../loading';
import useCTNavigate from '../../shared/hooks/use-ct-navigate';
import { formatFakturaAdresse, formatLev, formatLeveres, formatRekvirentFull, formatRekvisitionsDato, formatSingleLine, formatVenligHilsen } from './see-rekvisition-screen';

/** Status for indicator. Uses strings to supports inserting other statuses in the future without breaking compatibility*/
enum RekvisitionStatus {
    Created = 'created',
    Sent = 'sent',
    Received = 'received',
    Done = 'done'
}

/** Rekvisition data. Interface is sparse */
interface RekvisitionData {
    id: number;
    rekvnr: string;
    title: string;
    tilstand: RekvisitionStatus;
}

const Steps = ["Oprettet", "Sendt", "Modtaget", "Udført"];
const RekvisitionStatusIndicator = (cellInfo: { data: RekvisitionData }) => {
    const intStatus = Object.values(RekvisitionStatus).indexOf(cellInfo.data.tilstand);
    return Steps[intStatus];
}

const RekvisitionScreen = () => {
    const navigate = useCTNavigate();
    const { enqueueSnackbar } = useCentralizedSnackbar();
    const error = useQueryParam('error');
    const enhedId = useEnhedId();
    const [data, isLoading, isError] = useFetch<RekvisitionData[]>(`/Finans/rekvisition?enhedsys=${enhedId}`);

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
        }
    }, [error, enqueueSnackbar]);

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Kunne ikke hente rekvisitioner', { variant: 'error' });
        }
    }, [isError, enqueueSnackbar]);

    const updateRekvisition = async (rowData: RekvisitionData) => {
        navigate(`/Rekvisition/${rowData.id}`);
    }

    if (isLoading) {
        return <LoadingScreen />
    }

    return (
        <Container sx={{
            '& tr.dx-row.dx-data-row > td': {
                verticalAlign: 'middle'
            }
        }}>
            <Typography variant='h4'>Rekvisitioner</Typography>

            <DataGrid
                dataSource={data}
                rowAlternationEnabled
                showBorders
                allowColumnResizing
                allowColumnReordering
                width='100%'
                onRowClick={(e) => updateRekvisition(e.data)}
                hoverStateEnabled
            >
                <ColumnChooser enabled mode='select' />
                <StateStoring enabled type='localStorage' storageKey='RekvisitionScreenDataGridKey' />
                <SearchPanel visible />
                <GroupPanel visible />
                <Toolbar>
                    <Item name='groupPanel' location='before' />
                    <Item location='after'>
                        <NavLink to={`/${enhedId}/Rekvisition/Create`}>
                            <DxButton icon='add' text='Ny Rekvisition'></DxButton>
                        </NavLink>
                    </Item>
                    <Item name='columnChooserButton' />
                    <Item name='searchPanel' />
                </Toolbar>
                <Column caption='Rekv. Nr.' dataField='rekvisitionNr' width='auto' />
                <Column caption='Titel' dataField='rekvisitionTekst' width='auto' />
                <Column caption='Rekvirent' calculateCellValue={formatSingleLine(formatRekvirentFull)} allowGrouping />
                <Column caption='Faktura adresse' calculateCellValue={formatSingleLine(formatFakturaAdresse)} allowGrouping />
                <Column caption='Lev.tidspunkt' dataField='leveresTidspunkt' />
                <Column caption='Leverandør' calculateCellValue={formatSingleLine(formatLev)} visible={false} allowGrouping />
                <Column caption='Leveres' calculateCellValue={formatSingleLine(formatLeveres)} visible={false} allowGrouping />
                <Column caption='Knt.pers' dataField='leveresKontaktperson' visible={false} />
                <Column caption='Venlig Hilsen' calculateCellValue={formatSingleLine(formatVenligHilsen)} visible={false} allowGrouping />
                <Column caption='EAN-nummer' dataField='rekvisitionEannr' visible={false} />
                <Column caption='Rekvisitionsdato' calculateCellValue={formatSingleLine(formatRekvisitionsDato)} visible={false} allowGrouping />
                <Column caption='Kreditornr.' dataField='rekvisitionKreditornr' visible={false} />
                <Column caption='Enhednr' dataField='rekvisitionEnhed' visible={false} />
                <Column caption='Kontonr' dataField='kontoTekst' visible={false} />
                <Column caption='Tlf.' dataField='fakturaTlf' visible={false} />
                <Column dataField='tilstand' caption='Tilstand' cellRender={RekvisitionStatusIndicator} />
            </DataGrid>
        </Container>
    );
};

export default RekvisitionScreen;
