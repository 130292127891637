import './auto-form.css';

import {
    AutoFormStyle,
    IAutoFormHandle,
    IAutoFormProps,
    IAutoFormSubmitType,
    IGrupper,
    IItem,
    IItemProps,
    IValidation,
    defaultItem,
} from './interfaces-and-defaults';
import {
    ChangeEvent,
    FormEvent,
    Fragment,
    forwardRef,
    useCallback,
    useImperativeHandle,
    useRef,
    useState,
} from 'react';
import { EditToggler, Submitter } from './headers-footers';
import { Form, GroupItem } from 'devextreme-react/form';

import { AUFormControl } from './styling';
import Box from '@mui/material/Box/Box';
import { CenterLeftBox } from '../../mui/styled-mui';
import Divider from '@mui/material/Divider';
import GenerateDxFormItem from './dx-form-items';
import Grid from '@mui/material/Grid/Grid';
import { IDictionary } from '../../../shared/utils/types';
import ImageFileGrid from '../../file-displaying/image-file-grid';
import { Scrollable } from '../../misc/flex';
import Typography from '@mui/material/Typography/Typography';
import UtilsString from '../../../shared/utils/utils-string';
import __ from '../../../shared/utils/lodash-expansions';
import generateFormItem from './form-items';
import styles from './auto-form.module.css';
import { useArgsLayoutEffect } from '../../../shared/hooks/use-args';

const initValids = (grupper: IGrupper[]) => {
    const dict: IDictionary = {};
    const groups: IGrupper[] = [];
    grupper.forEach((gruppe) => {
        if (gruppe.items) {
            groups.push(gruppe);
        }
    });
    // console.log(groups, groups)
    for (const gruppe of groups) {
        for (const item of gruppe.items) {
            // Valid if not required
            if (item.required !== true) dict[item.id] = true;
            // Not empty string if string
            else if (typeof item.value === 'string')
                dict[item.id] = !UtilsString.IsNullOrWhitespace(item.value as string);
            // Not null if any other type
            else dict[item.id] = item.value != null;
        }
    }
    return dict;
};

const AutoForm = forwardRef<IAutoFormHandle, IAutoFormProps>(
    (
        {
            editing = true,
            allowEditing = true,
            onCancel = () => undefined,
            onSubmit = () => undefined,
            files = [],
            grupper = [],
            submitPosition = 'header',
            colCount,
            useDxForm = false,
            buttons,
            noValidate = true,
            onFieldDataChanged,
            style = AutoFormStyle.Default,
            ...props
        },
        forwardRef
    ) => {
        //#region Setup

        // Sorts on every render as checking for equality in a custom memoization is likely more expensive
        // const sortedItems = [...items].sort(sortBy.object.string('sort'))
        const sortedItems = grupper;

        //#endregion Setup

        const [isEditing, setIsEditing] = useState(editing);
        const [showError, setShowError] = useState(false);
        const [valids, setValids] = useState<IDictionary<boolean>>(() => initValids(sortedItems));
        const [allValid, setAllValid] = useState(() => Object.keys(valids).every((k) => valids[k]));
        const scrollRef = useRef<HTMLDivElement>(null);
        const formRef = useRef<HTMLFormElement>(null);

        //#region Validity and errors

        const setValid = (key: string) => (valid: boolean) => {
            // Er værdi anderledes end nuværende? Ellers skip nedenstående
            if (valid === valids[key]) return;

            // Tjek om alle er valide
            setAllValid(valid && Object.keys(valids).every((k) => valids[k] || k === key));
            // Set state
            setValids((vs) => {
                return { ...vs, [key]: valid };
            });
            // Skjul errors hvis man har rettet én
            if (valid) setShowError(false);
        };

        useArgsLayoutEffect(
            ([showError], [sortedItems, valids]) => {
                if (!showError) return;

                for (const { id } of sortedItems) {
                    if (valids[id]) continue;

                    const el = document.getElementById(`fc-${id}`);
                    if (el != null) {
                        el.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
                        break;
                    }
                }
            },
            [showError] as const,
            [sortedItems, valids] as const
        );

        //#endregion Validity and errors
        //#region Edit / Submit / Cancel

        const handleSubmit = async (e?: FormEvent<HTMLFormElement>) => {
            e?.preventDefault();
            console.log(e);

            const data = new FormData(formRef.current!);

            for (const d of data.entries()) console.log(d);

            if (!props.keepUnchanged) {
                //Remove unchanged items
                sortedItems.forEach((gruppe) => {
                    for (const item of gruppe.items) {
                        let value = String(item.value);
                        if (item.type === 'decimal') value = value.replace(/\./, ',');
                        if (value === data.get(item.id)) data.delete(item.id);
                    }
                });
            }

            // Append extra data before submitting
            if (props.formData !== undefined)
                for (const d of props.formData) {
                    if (UtilsString.IsNullOrWhitespace(d.filename)) data.append(d.name, d.value);
                    else data.append(d.name, d.value as Blob, d.filename);
                }

            // Get submit type
            const submitter = (e?.nativeEvent as SubmitEvent)?.submitter;
            const submitType = (submitter?.getAttribute('value') as IAutoFormSubmitType | null) ?? 'ref';

            await onSubmit?.(data, submitType);
        };

        /** Close the form if it was initially opened in edit mode. Otherwise just exit edit mode */
        const handleCancel: VoidFunction = useCallback(() => {
            if (isEditing && !editing) return setIsEditing(false);

            onCancel();
        }, [isEditing, editing, onCancel]);

        const handleHelp: () => void = useCallback(() => {
            if (!noValidate) {
                formRef.current?.reportValidity();
                return;
            }
            setShowError((e) => !e);
        }, [noValidate]);

        const editToggler = {
            [submitPosition]: (
                <EditToggler {...{ allowEditing: allowEditing, setEditing: setIsEditing, handleCancel }} />
            ),
        };
        const submitter = { [submitPosition]: <Submitter {...{ allValid, handleHelp, handleCancel, buttons }} /> };

        const ScrollableProps =
            submitPosition === 'none'
                ? {}
                : {
                    ...(isEditing ? submitter : editToggler),
                };

        useImperativeHandle<IAutoFormHandle, IAutoFormHandle>(forwardRef, () => ({
            submit: handleSubmit,
            cancel: handleCancel,
            help: handleHelp,
            allValid,
            element: formRef.current!,
        }));

        //#endregion Edit / Submit / Cancel

        if (useDxForm) {
            return (
                <form key='autoform' id={`auto-dx-form`} ref={formRef}>
                    <Form
                        id={`auto-form-inner`}
                        labelMode={props.labelMode ?? 'outside'}
                        labelLocation={props.labelLocation ?? 'top'}
                        formData={sortedItems.filter((gruppe) => gruppe.items.length > 0)}
                        colCount={1}
                        minColWidth={300}
                        onFieldDataChanged={(e) => onFieldDataChanged?.({ e, egenskaber: true })}
                    >
                        {sortedItems
                            .sort((a, b) => {
                                const sortA = a.items[0].sort ?? 0;
                                const sortB = b.items[0].sort ?? 0;
                                if (sortA < sortB) return -1;
                                if (sortA > sortB) return 1;
                                return 0;
                            })
                            .map((gruppe) => {
                                return (
                                    <GroupItem caption={gruppe.label} colSpan={1} colCount={colCount} key={gruppe.id}>
                                        {gruppe.items
                                            .sort((a, b) => {
                                                const sortA = a.sort ?? 0;
                                                const sortB = b.sort ?? 0;
                                                if (sortA < sortB) return -1;
                                                if (sortA > sortB) return 1;
                                                return 0;
                                            })
                                            .map((item) => {
                                                if (
                                                    item.type === 'choice' ||
                                                    item.type === 'combined' ||
                                                    item.type === 'lookup'
                                                ) {
                                                    item.props
                                                        ? item.props.radioProps
                                                            ? (item.props.radioProps.url = props.radioUrl)
                                                            : (item.props.radioProps = { url: props.radioUrl })
                                                        : (item.props = { radioProps: { url: props.radioUrl } });
                                                    item.props.radioProps!.putId = props.id;
                                                    item.props
                                                        ? item.props.lookupProps
                                                            ? (item.props.lookupProps.putUrl = props.radioUrl)
                                                            : (item.props.lookupProps = { putUrl: props.radioUrl })
                                                        : (item.props = { lookupProps: { putUrl: props.radioUrl } });
                                                    item.props.lookupProps!.putId = props.id;
                                                }
                                                return GenerateDxFormItem({
                                                    item,
                                                    props: {
                                                        colCount,
                                                        autoSubmit: props.autoSubmit,
                                                        service: props.service,
                                                    },
                                                });
                                            })}
                                    </GroupItem>
                                );
                            })}
                    </Form>
                </form>
            );
        }

        return (
            <Box
                ref={formRef}
                component='form'
                onSubmit={handleSubmit}
                noValidate={noValidate}
                sx={props.sx}
                autoComplete='off'
            >
                {/* disabled submit button. Stopper enter fra at submitte formen. Den rigtige submit button er i ovenstående <Submitter /> */}
                <button type='submit' disabled style={{ display: 'none' }} aria-hidden='true' />
                <Scrollable ref={scrollRef} {...ScrollableProps}>
                    <Grid
                        key={`grid`}
                        container
                        spacing={2}
                        justifyContent='center'
                        sx={{
                            paddingBottom: props.paddingBottom
                                ? typeof props.paddingBottom === 'number'
                                    ? props.paddingBottom
                                    : '120px'
                                : undefined,
                        }}
                    >
                        <>
                            {sortedItems.map((gruppe, index) => {
                                return (
                                    <Fragment key={`box-groupheader-${gruppe.id}`}>
                                        {!UtilsString.IsNullOrWhitespace(gruppe.label) &&
                                            !(isEditing && gruppe.items.every((item) => item.hideOnEdit)) && (
                                                // From the above, this is hidden if there is no label or if the user is editing and all items hides on edit
                                                <Grid
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        justfyContent: 'left',
                                                        background: index % 2 !== 0 ? '#f5f5f5' : '',
                                                    }}
                                                    aria-label={gruppe.label}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        sx={{ pt: '0px', pl: '0px', textAlign: 'left' }}
                                                    >
                                                        {gruppe.label}
                                                    </Typography>
                                                </Grid>
                                            )}
                                        {gruppe.items.map((it: IItem) => {
                                            const item = __.deepMerge(defaultItem, it);
                                            const validation: IValidation = {
                                                showError,
                                                isValid: valids[item.id],
                                                setValid: setValid(item.id),
                                            };
                                            const itemProps: IItemProps = {
                                                item,
                                                scrollRef,
                                                validation,
                                                generalInputProps: {
                                                    label: item.required ? '' : undefined, // Mui's required stjerne vises kun hvis label findes, og ikke er '',
                                                    required: item.required ?? undefined,
                                                    disabled: (!isEditing || item.readonly) ?? undefined, // Disable hvis der ikke redigeres
                                                    id: item.id,
                                                    placeholder: item.placeholder,
                                                    defaultValue: item.value,
                                                    name: item.id,
                                                    autoComplete: item.autoComplete ?? undefined,
                                                    helperText:
                                                        item.required && validation.showError && !validation.isValid
                                                            ? 'Obligatorisk'
                                                            : undefined,

                                                    // valid hvis item ikke er required eller ikke er null/tom
                                                    onChange: (e: ChangeEvent<HTMLInputElement>) => {
                                                        validation.setValid(
                                                            item.required !== true ||
                                                            !UtilsString.IsNullOrWhitespace(e.target.value)
                                                        );
                                                    },
                                                },
                                            };

                                            const hasLabel =
                                                !item.hideElements?.label &&
                                                !UtilsString.IsNullOrWhitespace(item.label);
                                            const hasDescription =
                                                !item.hideElements?.description &&
                                                item.description &&
                                                !UtilsString.IsNullOrWhitespace(item.description);
                                            const hasDecriptionText =
                                                item.descriptionText &&
                                                !UtilsString.IsNullOrWhitespace(item.descriptionText);

                                            return (
                                                <Grid
                                                    item
                                                    className={styles[item.style ?? style]}
                                                    xs={item.size!.xs}
                                                    sm={item.size!.sm}
                                                    md={item.size!.md}
                                                    lg={item.size!.lg}
                                                    xl={item.size!.xl}
                                                    key={`form-item-${item.id}`}
                                                    sx={{
                                                        xs: { paddingTop: '0px' },
                                                        display: isEditing && item.hideOnEdit ? 'none' : undefined,
                                                        background: index % 2 !== 0 ? '#f5f5f5' : '',
                                                    }}
                                                    aria-label={item.label ?? item.id}
                                                >
                                                    <AUFormControl
                                                        id={`fc-${item.id}`}
                                                        fullWidth
                                                        error={validation.showError && !validation.isValid}
                                                    >
                                                        <Grid container spacing={1}>
                                                            {!item.hideElements?.divider && (
                                                                <Divider
                                                                    style={{
                                                                        width: 'calc(100% - 8px)',
                                                                        paddingBottom: '0px',
                                                                        paddingTop: '3px',
                                                                    }}
                                                                />
                                                            )}
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={
                                                                    (props.labelPosition ?? item.labelPosition) ===
                                                                        'top'
                                                                        ? 5
                                                                        : 12
                                                                }
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    justifyContent: 'space-between',
                                                                }}
                                                            >
                                                                {(hasLabel || hasDescription) && (
                                                                    <CenterLeftBox /*pb='15px'*/>
                                                                        {hasLabel && (
                                                                            <Typography textAlign='left' variant='h6'>
                                                                                {item.label}
                                                                            </Typography>
                                                                        )}
                                                                        {hasDescription && (
                                                                            <Typography
                                                                                textAlign='left'
                                                                                whiteSpace={'pre-line'}
                                                                            >
                                                                                <div
                                                                                    dangerouslySetInnerHTML={{
                                                                                        __html:
                                                                                            item.description?.replaceAll(
                                                                                                '\\n',
                                                                                                '\n'
                                                                                            ) ?? '',
                                                                                    }}
                                                                                />
                                                                            </Typography>
                                                                        )}
                                                                    </CenterLeftBox>
                                                                )}
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={
                                                                    (props.labelPosition ?? item.labelPosition) ===
                                                                        'top'
                                                                        ? 7
                                                                        : 12
                                                                }
                                                                pr='8px'
                                                                sx={{ paddingTop: '0px !important' }}
                                                            >
                                                                {generateFormItem(itemProps)}
                                                            </Grid>
                                                            {hasDecriptionText && (
                                                                <Box>
                                                                    <Typography
                                                                        textAlign='left'
                                                                        whiteSpace={'pre-line'}
                                                                        sx={{
                                                                            verticalAlign: 'bottom',
                                                                            fontSize: '15px',
                                                                            pl: '8px',
                                                                        }}
                                                                    >
                                                                        <div
                                                                            dangerouslySetInnerHTML={{
                                                                                __html:
                                                                                    item.descriptionText?.replaceAll(
                                                                                        '\\n',
                                                                                        '\n'
                                                                                    ) ?? '',
                                                                            }}
                                                                        />
                                                                    </Typography>
                                                                </Box>
                                                            )}
                                                        </Grid>
                                                    </AUFormControl>
                                                </Grid>
                                            );
                                        })}
                                        <Divider
                                            style={{
                                                width: 'calc(100%)',
                                                paddingBottom: '0px',
                                                paddingTop: '12px',
                                                background: index % 2 !== 0 ? '#f5f5f5' : '',
                                            }}
                                        />
                                    </Fragment>
                                );
                            })}
                            <Grid item xs={12}>
                                <ImageFileGrid files={files} />
                            </Grid>
                        </>
                    </Grid>
                </Scrollable>
            </Box>
        );
    }
);

export default AutoForm;
